@import "~bootstrap-icons/font/bootstrap-icons.css";

/* - - - - - - - - - - - - - Lemon Milk - - - - - - - - - - - - - */
@font-face {
    font-family: 'Lemon Milk';
    src: url(assets/fonts/lemon_milk/LEMONMILK-Regular.otf) format("opentype");
}
@font-face {
    font-family: 'Lemon Milk light';
    src: url(assets/fonts/Lemon_milk/LEMONMILK-Light.otf) format("opentype");
    letter-spacing: 0.1rem;
}
@font-face {
    font-family: 'Lemon Milk medium';
    src: url(assets/fonts/Lemon_milk/LEMONMILK-Medium.otf) format("opentype");
}
@font-face {
    font-family: 'Lemon Milk bold';
    src: url(assets/fonts/Lemon_milk/LEMONMILK-Bold.otf) format("opentype");
}


 /* - - - - - - - - - - - - - Lemon Milk 2 - - - - - - - - - - - - - */
@font-face {
    font-family: 'Lemon Milk-2';
    src: url(assets/fonts/Lemon_Milk_2022/LemonMilk.otf) format("opentype");
}
@font-face {
    font-family: 'Lemon Milk-2 light';
    src: url(assets/fonts/Lemon_Milk_2022/LemonMilklight.otf) format("opentype");
    font-weight: light;
}

@font-face {
    font-family: 'Lemon Milk-2 bold';
    src: url(assets/fonts/Lemon_Milk_2022/LemonMilkbold.otf) format("opentype");
}

 /* - - - - - - - - - - - - - Lemon Milk Pro - - - - - - - - - - - - - */
@font-face {
    font-family: 'Lemon Milk-Pro';
    src: url(assets/fonts/lemon_milk_pro/lemon_milk_pro_regular.otf) format("opentype");
    
}

@font-face {
    font-family: 'Lemon Milk-Pro light';
    src: url(assets/fonts/lemon_milk_pro/lemon_milk_pro_light.otf) format("opentype");
}

@font-face {
    font-family: 'Lemon Milk-Pro medium';
    src: url(assets/fonts/lemon_milk_pro/lemon_milk_pro_medium.otf) format("opentype");
}

@font-face {
    font-family: 'Lemon Milk-Pro bold';
    src: url(assets/fonts/lemon_milk_pro/lemon_milk_pro_bold.otf) format("opentype");
}


:root {
    --shade-1: rgb(229,254,255);
    --shade-2: rgb(198,179,179);
    --shade-3: rgb(245,245,245);
    --shade-5: rgb(112,112,112);
    --shade-7: rgb(181,178,178,0.54);
    --shade-8: rgb(84,245,37,0.28);
    --shade-9: rgb(52,168,17,.50);
    --shade-10: rgb(168,17,9,.50);
    --shade-11: rgb(27,245,234,.16);
    --shade-12: rgb(0, 168,159,.50);
    --core-dark-red: rgb(197,0,0);
    --core-med-red: rgb(220,0,0);
    --core-light-red: rgb(239,0,7);
    --core-blue: rgb(0,34,198);
    --core-gray: rgb(204,204,204);
}

body {
    background-image: linear-gradient(rgb(236, 162, 162), rgba(255, 255, 0, 0.149));
    /* background-color:  */
    /* rgb(11,150,212,0.22); */
    /* rgb(11, 137, 222, 0.2); */
    /* rgb(27,245,234,.14); */
    /* background-color: rgba(117, 114, 0, 0.14); */
    /* background-color: rgb(52,168,17,.12); */
    /* background-color: rgb(65,46,92, 0.2); */
    /* rgb(84,245,37,0.08); */
     /* rgb(181,178,178,0.54); */
     /* rgb(168,17,9,.50); */
    font-family: Lemon Milk light;
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
    /* background-attachment: fixed; */
}

/* .temp {
    font-family: Lemon Milk medium;
    font-size: 1.5em;
} */

/* .tool-kit-icon { */
    /* width: 20vw; */
    /* background-image: url(); */
    /* margin: auto; */
    /* padding-top: 60px; */
    /* opacity: 50%;  */
    
/* } */
a {
    color: unset;
    text-decoration: unset;
}

.l-case {
    font-family: Lemon Milk-Pro light;
    letter-spacing: 0.03rem;
}

hr {
    color: white;
}